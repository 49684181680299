import { SetModel, BrickModel, SetBrick } from "../models";
import RebrickableService from "../services/RebrickableService";

export const LocalStorageHelperProject = {
    addProject: (setNr: string, updateProjects: () => void): Promise<SetModel> => {
        return new Promise((resolve) => {
            setNr = setNr.includes("-") ? setNr : `${setNr}-1`;
            const dublicate = localStorage.getItem(`lego-counter-set-${setNr}`) ? true : false;
            if (!dublicate) {
                RebrickableService.getSet(setNr).then((value) => {
                    const project: SetModel = {
                        bricks: [],
                        uniqueBricks: 0,
                        downloadedBricks: 0,
                        set_num: value.set_num,
                        num_parts: value.num_parts,
                        name: value.name,
                        set_img_url: value.set_img_url
                    };
                    RebrickableService.getSetBricks(setNr, value.num_parts).then(async (value) => {
                        const uniqueBricks: any = [];
                        value.results.forEach((brick: any) => {
                            let unique = true;
                            uniqueBricks.forEach((uniqueBrick: any) => {
                                if (brick.part.part_num === uniqueBrick.part.part_num && brick.color.id === uniqueBrick.color.id) {
                                    unique = false;
                                    uniqueBrick.quantity = brick.quantity > uniqueBrick.quantity ? brick.quantity : uniqueBrick.quantity;
                                }
                            });
                            if (unique) {
                                uniqueBricks.push(brick);
                            }
                        });
                        project.uniqueBricks = uniqueBricks.length;
                        localStorage.setItem(`lego-counter-set-${setNr}`, JSON.stringify(project));
                        resolve(project);
                        updateProjects();
                        for (let i = 0; i < uniqueBricks.length; i++) {
                            const brick = uniqueBricks[i];
                            await LocalStorageHelperBrick.addBrick(brick.part.part_num, brick.color.id, 1000);
                            project.bricks.push({ part_num: brick.part.part_num, color_id: brick.color.id.toString(), count: 0, quantity: brick.quantity });
                            project.downloadedBricks = project.bricks.length;
                            localStorage.setItem(`lego-counter-set-${setNr}`, JSON.stringify(project));
                        }
                    });
                });
            }
        });
    },
    getProjects: (): Array<SetModel> => {
        const storSetsOverview: SetModel[] = [];
        Object.keys(localStorage).forEach((item) => {
            if (item.includes("lego-counter-set-")) {
                storSetsOverview.push(JSON.parse(localStorage.getItem(item) ?? "{}"));
            }
        });
        return storSetsOverview;
    },
    getProject: (setNr: string): SetModel => {
        setNr = setNr.includes("-") ? setNr : `${setNr}-1`;
        const storSet = JSON.parse(localStorage.getItem(`lego-counter-set-${setNr}`) ?? "{}");
        return storSet;
    },
    removeProject: (setNr: string): void => {
        localStorage.removeItem(`lego-counter-set-${setNr}`);
    },

    editCount: (setNr: string, increase: boolean, part_num: string, color_id: string, amount: number): void => {
        const storCountSets = JSON.parse(localStorage.getItem(`lego-counter-set-${setNr}`) ?? '{"name": "error", "bricks": []');
        storCountSets.bricks.forEach((brick: SetBrick, index: number) => {
            if (brick.part_num === part_num && brick.color_id === color_id) {
                storCountSets.bricks[index].count = increase ? brick.count + amount : brick.count - amount;
            }
        });
        localStorage.setItem(`lego-counter-set-${setNr}`, JSON.stringify(storCountSets));
    }
};

export const LocalStorageHelperBrick = {
    addBrick: (part_num: string, color_id: string, delay: number): Promise<boolean> => {
        return new Promise((resolve) => {
            const storBricks = JSON.parse(localStorage.getItem("lego-counter-bricks") ?? '{"bricks": []}');
            let dublicate = false;
            storBricks?.bricks?.forEach((brick: BrickModel) => {
                if (brick.id === `${part_num}-${color_id}`) {
                    dublicate = true;
                }
            });
            if (!dublicate) {
                window.setTimeout(function () {
                    RebrickableService.getBrick(part_num, color_id)
                        .then((value) => {
                            storBricks.bricks.push({
                                id: `${part_num}-${color_id}`,
                                part_img_url: value.part_img_url,
                                elements: value.elements
                            });
                            localStorage.setItem("lego-counter-bricks", JSON.stringify(storBricks));
                            resolve(true);
                        })
                        .catch(() => {
                            LocalStorageHelperBrick.addBrick(part_num, color_id, 10000).then(() => {
                                resolve(true);
                            });
                        });
                }, delay);
            } else {
                resolve(true);
            }
        });
    },
    getBrick: (part_num: string, color_id: string): BrickModel => {
        let searchBrick: BrickModel = { id: "0", part_img_url: "", elements: ["", ""] };
        const storBricks = JSON.parse(localStorage.getItem(`lego-counter-bricks`) ?? '{"bricks": []');
        storBricks.bricks.forEach((brick: BrickModel) => {
            if (brick.id === `${part_num}-${color_id}`) {
                searchBrick = brick;
            }
        });
        return searchBrick;
    }
};
