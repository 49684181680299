import React, { FunctionComponent, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, ButtonGroup, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table } from "@material-ui/core";
import { LocalStorageHelperProject } from "../helpers";
import history from "../router/History";
import { SetModel } from "../models";
import { CircularProgressWithLabel } from "../components";

const useStyles = makeStyles({
    tableContainer: {
        marginTop: "50px"
    },
    img: {
        maxWidth: "200px"
    }
});

const ProjectTable: FunctionComponent<any> = ({ sets, updateProject }) => {
    const classes = useStyles();
    const [rows, setRow] = useState([{ set_img_url: "string", set_num: "string", name: "string", num_parts: 0 }]);

    useEffect(() => {
        const tempRow: Array<SetModel> = [];
        sets?.forEach((set: SetModel) => {
            tempRow.push(set);
        });
        setRow(tempRow);
    }, [sets]);

    const removeSet = (setNr: string) => {
        LocalStorageHelperProject.removeProject(setNr);
        updateProject();
    };

    const countSet = (setNr: string): void => {
        history.push(`set/${setNr}`);
    };

    return (
        <TableContainer className={classes.tableContainer} component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Bild</TableCell>
                        <TableCell>Nummer</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Teile</TableCell>
                        <TableCell>Download</TableCell>
                        <TableCell align="right">Edit</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row: any) => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                                <img className={classes.img} src={row.set_img_url} alt={row.name}></img>
                            </TableCell>
                            <TableCell>{row.set_num}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.num_parts}</TableCell>
                            <TableCell>
                                <CircularProgressWithLabel value={(row.downloadedBricks / row.uniqueBricks) * 100} />
                            </TableCell>
                            <TableCell align="right">
                                <ButtonGroup orientation="vertical" variant="contained" color="secondary">
                                    <Button disabled={row.uniqueBricks - row.downloadedBricks !== 0} onClick={() => countSet(row.set_num)}>
                                        Zählen
                                    </Button>
                                    <Button onClick={() => removeSet(row.set_num)}>Löschen</Button>
                                </ButtonGroup>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ProjectTable;
