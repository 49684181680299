import React, { FunctionComponent } from "react";
import { observer } from "mobx-react-lite";
import { Route, Router, Switch } from "react-router-dom";
import history from "./History";
import { ProjectOverviewPageContainer, ProjectDetailPageContainer } from "../containers";

/**
 * App router
 *
 */
const AppRouter: FunctionComponent<any> = () => {
    return (
        <>
            <Router history={history}>
                <Switch>
                    <Route path="/" exact={true} component={ProjectOverviewPageContainer} />
                </Switch>
            </Router>
            <Router history={history}>
                <Switch>
                    <Route path="/set/:id" exact={true} component={ProjectDetailPageContainer} />
                </Switch>
            </Router>
        </>
    );
};

export default observer(AppRouter);
