import React, { FunctionComponent } from "react";
import { observer } from "mobx-react-lite";
import "./App.css";
import AppRouter from "./router/AppRouter";

/**
 * Main app component
 *
 */
const App: FunctionComponent = () => {
    return (
        <div id="app" className="App">
            <AppRouter />
        </div>
    );
};

export default observer(App);
