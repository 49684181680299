import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Container, ButtonGroup, TextField, makeStyles, Snackbar } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { LocalStorageHelperProject, LocalStorageHelperBrick } from "../helpers";
import { TableOptionsModel } from "../models";

const useStyles = makeStyles({
    input: {
        marginBottom: "20px"
    },
    image: {
        height: "50px"
    }
});

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const options: TableOptionsModel = {
    download: false,
    print: false,
    filter: true,
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    responsive: "standard",
    customSort: (data: any, colIndex: number, order: any) => {
        return data.sort((a: any, b: any) => {
            return (parseInt(a.data[colIndex]) < parseInt(b.data[colIndex]) ? -1 : 1) * (order === "desc" ? 1 : -1);
        });
    }
};

const ProjectDetail: FunctionComponent<any> = ({ project }) => {
    const classes = useStyles();
    const [countProject, setCountProject] = useState(LocalStorageHelperProject.getProject(project));
    const [tableData, setTableData] = useState([["", "", "", "", "", ["", 0]]]);
    const [title, setTitle] = useState("");
    const [count, setCount] = useState(1);
    const [openAdd, setOpenAdd] = useState(false);
    const [openRemove, setOpenRemove] = useState(false);

    useEffect(() => {
        const parseData = (): Array<Array<string | Array<string | number>>> => {
            const array: Array<Array<string | Array<string | number>>> = [];
            countProject.bricks.forEach((brick) => {
                const part_img_url = LocalStorageHelperBrick.getBrick(brick.part_num, brick.color_id).part_img_url;
                const id = LocalStorageHelperBrick.getBrick(brick.part_num, brick.color_id).elements.join(", ");
                array.push([
                    part_img_url,
                    id,
                    brick.color_id,
                    brick.count.toString(),
                    (brick.quantity - brick.count).toString(),
                    [`${brick.part_num}-${brick.color_id}`, brick.quantity - brick.count, brick.count]
                ]);
            });
            return array;
        };
        setTitle(countProject.name);
        setTableData(parseData());
    }, [countProject, project]);

    const onCount = (increase: boolean, partid: string, amount = count) => {
        if (increase) {
            setOpenAdd(true);
        } else {
            setOpenRemove(true);
        }
        LocalStorageHelperProject.editCount(project, increase, partid.split("-")[0], partid.split("-")[1], amount);
        setCountProject(LocalStorageHelperProject.getProject(project));
    };

    const columns = [
        {
            name: "Bild",
            options: {
                customBodyRender: (value: string) => <img className={classes.image} src={value} alt="brick" />
            }
        },
        "ID",
        "Farbe",
        "gezählt",
        "fehlend",
        {
            name: "Aktion",
            options: {
                customBodyRender: (value: string | Array<string | number>) => (
                    <ButtonGroup variant="contained" color="secondary" aria-label="contained primary button group">
                        <Button disabled={value[1] < 1} onClick={() => onCount(true, value[0].toString(), parseInt(`${value[1]}`))}>
                            +{value[1]}
                        </Button>
                        <Button disabled={value[1] < 5} onClick={() => onCount(true, value[0].toString(), 5)}>
                            +5
                        </Button>
                        <Button disabled={value[1] < 10} onClick={() => onCount(true, value[0].toString(), 10)}>
                            +10
                        </Button>
                        <Button disabled={value[1] < count} onClick={() => onCount(true, value[0].toString())}>
                            +
                        </Button>
                        <Button disabled={value[2] < count} onClick={() => onCount(false, value[0].toString())}>
                            -
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ];

    return (
        <>
            <Container>
                <h2>{title}</h2>
            </Container>
            <Snackbar open={openAdd} autoHideDuration={3000} onClose={() => setOpenAdd(false)}>
                <Alert onClose={() => setOpenAdd(false)} severity="success">
                    Steine hinzugefügt
                </Alert>
            </Snackbar>
            <Snackbar open={openRemove} autoHideDuration={3000} onClose={() => setOpenRemove(false)}>
                <Alert onClose={() => setOpenRemove(false)} severity="error">
                    Steine abgezogen
                </Alert>
            </Snackbar>
            <Container>
                <TextField
                    className={classes.input}
                    type="number"
                    label="Anzahl"
                    variant="outlined"
                    value={count}
                    onChange={(event: any) => setCount(parseInt(event.target.value))}
                />
                <MUIDataTable title={"Bricks"} data={tableData} columns={columns} options={options} />
            </Container>
        </>
    );
};

export default ProjectDetail;
