import ApiService from "./ApiService";

const RebrickableService = {
    async getSet(id: string): Promise<any> {
        return ApiService.get<any>(`https://rebrickable.com/api/v3/lego/sets/${id}?key=${process.env.REACT_APP_CUSTOM_Rebrickable_Key}`);
    },
    async getSetBricks(id: string, amount: number): Promise<any> {
        return ApiService.get<any>(
            `https://rebrickable.com/api/v3/lego/sets/${id}/parts?page_size=${amount}&key=${process.env.REACT_APP_CUSTOM_Rebrickable_Key}`
        );
    },
    async getBrick(part_num: string, color_id: string): Promise<any> {
        return ApiService.get<any>(
            `https://rebrickable.com/api/v3/lego/parts/${part_num}/colors/${color_id}?key=${process.env.REACT_APP_CUSTOM_Rebrickable_Key}`
        );
    }
};

export default RebrickableService;
