import React, { FunctionComponent } from "react";
import { PageHeader, ProjectOverview } from "../components";
import { observer } from "mobx-react-lite";

const ProjectOverviewPageContainer: FunctionComponent = () => {
    const projects = ["123456"];

    return (
        <>
            <PageHeader />
            <ProjectOverview projects={projects} />
        </>
    );
};

export default observer(ProjectOverviewPageContainer);
