import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#fff",
            light: "rgba(77,77,77,0.5)"
        },
        secondary: {
            main: "#ffcf00"
        },
        error: {
            main: red.A400
        },
        background: {
            default: "#ffcf00"
        }
    },
    props: {
        MuiButton: {
            color: "default",
            variant: "contained"
        },
        MuiInput: {
            color: "secondary"
        },
        MuiFormControl: {
            variant: "outlined"
        },
        MuiCircularProgress: {
            color: "secondary",
            size: 60
        },
        MuiTextField: {
            variant: "outlined"
        },
        MuiLinearProgress: {
            color: "secondary"
        }
    },
    overrides: {
        MuiButton: {
            containedSecondary: {
                color: "#fff"
            }
        },
        MuiBreadcrumbs: {
            root: {
                "& .MuiBreadcrumbs-ol": {
                    cursor: "pointer"
                }
            }
        }
    }
});

export default theme;
