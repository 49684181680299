import React, { FunctionComponent } from "react";
import { Container, makeStyles, createStyles } from "@material-ui/core";
import history from "../router/History";

const useStyles = makeStyles(() =>
    createStyles({
        headline: {
            textAlign: "center",
            backgroundImage: 'url("/assets/brick.png")',
            backgroundRepeat: "no-repeat",
            backgroundSize: "200px 113px",
            height: "115px",
            backgroundPosition: "center top",
            paddingTop: "23px",
            textShadow: "0px 0px 8px #000",
            color: "white",
            fontSize: "37px",
            cursor: "pointer"
        }
    })
);

const PageHeader: FunctionComponent = () => {
    const classes = useStyles();

    return (
        <Container>
            <h1 className={classes.headline} onClick={() => history.push("/")}>
                Lego Counter
            </h1>
        </Container>
    );
};
export default PageHeader;
