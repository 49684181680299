import axios, { AxiosResponse, AxiosRequestConfig } from "axios";

const options: AxiosRequestConfig = {
    method: "get",
    responseType: "json",
    headers: ""
};

/**
 * Api Service mapper
 */
const ApiService = {
    /**
     * HTTP get
     *
     * @template T
     * @param {string} [url=""]
     * @param {AxiosRequestConfig} [optionsCustom={}]
     * @return {*}  {Promise<T>}
     */
    async get<T>(url = "", optionsCustom: AxiosRequestConfig = {}): Promise<T> {
        return await axios
            .request<T>({
                ...options,
                ...optionsCustom,
                url
            })
            .then((response: AxiosResponse) => response.data);
    }
};

export default ApiService;
