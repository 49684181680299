import React, { FunctionComponent, useState } from "react";
import { Container, Button, TextField, makeStyles, createStyles } from "@material-ui/core";
import { LocalStorageHelperProject } from "../helpers";
import { ProjectTable } from "../components";

const useStyles = makeStyles(() =>
    createStyles({
        form: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        input: {
            marginRight: "20px"
        },
        dialog: {
            padding: "10px 30px 30px"
        }
    })
);

const DamageOverviewTable: FunctionComponent<any> = () => {
    const classes = useStyles();
    const [setNumber, setSetNumber] = useState("");
    const [sets, setSets] = useState(LocalStorageHelperProject.getProjects());

    const updateProjects = () => {
        setSets(LocalStorageHelperProject.getProjects());
    };

    const getProject = async () => {
        if (setNumber !== "") {
            const project = await LocalStorageHelperProject.addProject(setNumber, updateProjects);
            updateBrickCount(project.uniqueBricks);
        }
    };

    const updateBrickCount = (targetBricks: number) => {
        setTimeout(() => {
            updateProjects();
            const project = LocalStorageHelperProject.getProject(setNumber);
            const bricks = project.bricks.length;
            if (bricks !== targetBricks) {
                updateBrickCount(targetBricks);
            }
        }, 1000);
    };

    return (
        <>
            <Container className={classes.form}>
                <TextField
                    className={classes.input}
                    label="Setnummer"
                    variant="outlined"
                    value={setNumber}
                    onChange={(event: any) => setSetNumber(event.target.value)}
                />
                <Button
                    variant="contained"
                    onClick={() => {
                        getProject();
                    }}
                    disabled={setNumber === ""}
                >
                    Neues Projekt
                </Button>
            </Container>
            <Container>
                <ProjectTable sets={sets} updateProject={updateProjects} />
            </Container>
        </>
    );
};

export default DamageOverviewTable;
