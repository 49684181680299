import React, { FunctionComponent } from "react";
import { PageHeader, ProjectDetail } from "../components";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";
import { ProjectDetailParamTypes } from "../models";

const ProjectDetailPageContainer: FunctionComponent = () => {
    const { id } = useParams<ProjectDetailParamTypes>();

    return (
        <>
            <PageHeader />
            <ProjectDetail project={id} />
        </>
    );
};

export default observer(ProjectDetailPageContainer);
